<template>
  <div class="success_container">
    <img src="@/assets/ok.png" alt="">
    <p class="result">感谢您耐心认真的评价，请静待结果</p>
  </div>
</template>

<script>
import { getCulture } from '@/api/assessment'
export default {
  mounted() {
    document.title = sessionStorage.getItem('name_zl') + '-企业文化考核'
  },
  created() {
    const date = new Date()
    const y = date.getMonth() == 0 ? date.getFullYear() - 1 : date.getFullYear()
    const m = date.getMonth() == 0 ? 12 : date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()
    const params = {
      exam_date: y + '-' + m
    }
    getCulture(params).then(res => {
      if (res.result.staff_list) {
        this.$router.push({
          path: '/assessment',
          query: {
            exam_date: y + '-' + m,
            isAgain: 1
          }
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
.success_container {
  margin-top: 60px;
  img {
    margin-top: 200px;
  }
  .result {
    color: #f00;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 220px;
  }
}
</style>
